body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;


  font-family: "Courier New";
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    "Courier New";
}

html {
  overflow: hidden;
  background-color: #a6c6b2;
  overscroll-behavior: none;
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

body, #root {
  height: 100%;
}